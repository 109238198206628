import React, {createContext, useEffect, useState} from "react";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Modal from "../Components/Modal";
import {ModalProvider} from "../Utils/ModalsContext";
import {LoginProvider} from "../Utils/LoginContext";
import HomePage from "../Pages/HomePage";
import {BrowserRouter, Routes, Route, Navigate,} from "react-router-dom";
import LoginPage from "../Pages/LoginPage";
import NavBar from "../Components/NavBar";


function App() {
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentModalData, setCurrentModalData] = useState(null)
    const [isLoggedIN, setLoggedIN] = useState(false)

    return (<>
            <div className={"App"}>
                <LoginProvider value={{isLoggedIN, setLoggedIN}}>
                    <ModalProvider value={{isModalOpen, setModalOpen, currentModalData, setCurrentModalData}}>
                        <NavBar/>
                        <main className={"pt-5"}>
                            {!isLoggedIN ?
                                <Routes>
                                    <Route
                                        path="*"
                                        element={<Navigate to="/login" replace/>}
                                    />
                                    <Route path="/login" element={<LoginPage/>}/>
                                </Routes>
                                :
                                <Routes>
                                    <Route
                                        path="*"
                                        element={<Navigate to="/dashboard" replace/>}
                                    />
                                    <Route path="/dashboard" element={<HomePage/>}/>
                                </Routes>
                            }
                        </main>

                        {/*Modal Containers*/}
                        <TransitionGroup>
                            <CSSTransition
                                key={isModalOpen}
                                classNames="fade"
                                timeout={300}
                            >
                                <>
                                    {isModalOpen && <Modal openState={isModalOpen} setState={setModalOpen} data={currentModalData}/>}
                                </>

                            </CSSTransition>
                        </TransitionGroup>
                    </ModalProvider>
                </LoginProvider>
            </div>

        </>

    );
}

export default App;
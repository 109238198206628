import {useEffect, useState} from "react";
import tc from "../Assets/tc-new.png";
import pullData from "../Utils/API";
import {useContext} from "react";
import ModalsContext from "../Utils/ModalsContext";

export default function Item(transaction){
    const [userData, setUserData] = useState(null)
    const [watchData, setWatchData] = useState(null)

    const td = transaction.data
    const {setModalOpen, currentModalData, setCurrentModalData} = useContext(ModalsContext)

    useEffect(()=>{
        if (userData === null){
            let formD = new FormData();
            formD.append("user_id", td.user_id)

            pullData.getUserDetails(formD).then(r => {
                if (r.success){
                    setUserData(r.user[0])
                }else{
                    alert("Unable to fetch transaction data.")
                }
            })
        }

        if (watchData === null){
            let formD = new FormData();
            formD.append("watch_serial", td.watch_id)

            pullData.getWatchInfo(formD).then(r => {
                if (r.success){
                    setWatchData(r.watch[0])
                }else{
                    alert("Unable to fetch transaction data.")
                }
            })
        }

    }, [userData, watchData])

    return(
        <div className="row border-bottom border-dark">
            <div className="col-12 col-md-2 py-2 text-center">
                <img src={tc} alt="watch" className={"img-fluid"}/>
            </div>
            <div className="col-12 col-md-7 py-2 d-flex align-items-center">
                <div className={"ps-3"}>
                    <h4 className={"fw-bold h5"}>{userData?.firstname +" "+ userData?.lastname}</h4>
                    <p className={"fw-light m-0 small"}>{userData?.email} | {userData?.mobile}</p>
                    <p className={"fw-light m-0 small"}>{userData?.full_address}</p>
                </div>
            </div>
            <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                <div>
                    <button className={"btn btn-link text-decoration-none"}
                            onClick={()=>{
                                setCurrentModalData({user: userData, watch: watchData, blockchainData: td.blockchain_data})
                                setModalOpen(true)
                            }}>
                        View Details 👉
                    </button>
                </div>
            </div>
        </div>
    )
}
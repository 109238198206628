let apiURL = "https://apis.arab-watches.com"
// let apiURL = "http://192.168.1.15/fadi_apis"


let pullData = {
    getAllTransactions: (formdata) => {
        return fetch(`${apiURL}/get_transactions.php`, {
            method: 'post',
            body: formdata
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    adminAuth: (formdata) => {
        return fetch(`${apiURL}/admin_login.php`, {
            method: 'post',
            body: formdata
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    getUserDetails: (userID) => {
        return fetch(`${apiURL}/get_user.php`, {
            method: 'post',
            body: userID
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },

    getWatchInfo: (data) => {
        return fetch(`${apiURL}/get_watch.php`, {
            method: 'post',
            body: data
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    updateShipment: (data) => {
        return fetch(`${apiURL}/update_shipment.php`, {
            method: 'post',
            body: data
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    listItems: (data) => {
        return fetch(`${apiURL}/list_items.php`, {
            method: 'post',
            body: data
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
}

export default pullData;

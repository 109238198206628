import {useEffect, useState} from "react";
import pullData from "../Utils/API";
import Item from "../Components/Item";
import Item_verified from "../Components/Item_verified";

export default function HomePage() {
    const [allTransactions, setAllTransactions] = useState(null)
    const [allWatches, setAllWatches] = useState(null)


    useEffect(() => {
        if (allTransactions === null) {
            let formD = new FormData();
            formD.append("get_transactions", "yes")
            pullData.getAllTransactions(formD).then(r => {
                if (r.success) {
                    setAllTransactions(r.watch_data)
                } else {
                    console.log("Unable to fetch transaction data.")
                }
            })
        }
    }, [allTransactions])

    useEffect(() => {
        if (allWatches === null) {
            let formD = new FormData();
            formD.append("get_watches", "yes")
            pullData.listItems(formD).then(r => {
                if (r.success) {
                    console.log(r)
                    setAllWatches(r.watch_data)
                } else {
                    console.log("Unable to fetch watch data.")
                }
            })
        }
    }, [allWatches])

    return (
        <>
            <div className={"container text-white"} id={"homepage"}>
                <div className="row">
                    <div className="col-md-6">
                        <p className={"h5 fw-light"}>Your Metamask wallet total</p>
                        <h1 className={"fw-bold display-5 py-3"}>$ 100K</h1>
                    </div>
                </div>

                <div className={"mt-4"}>
                    <p className={"h5 fw-light"}>Your performance for this month April 2022</p>
                </div>

                <div className="container-fluid pt-2">
                    <div className="row">
                        <div className="col-md-4 border border-dark p-3 mb-3">
                            <p className={"fw-light"}>Earnings</p>
                            <h3 className={"fw-bold display-6"}>$ 198K</h3>
                            <span className={"small fw-lighter"}>14% more than last month</span>
                        </div>
                        <div className="col-md-4 border border-dark p-3 mb-3">
                            <p className={"fw-light"}>Items Sold</p>
                            <h3 className={"fw-bold display-6"}>198</h3>
                            <span className={"small fw-lighter"}>14% more than last month</span>
                        </div>
                        <div className="col-md-4 border border-dark p-3 mb-3">
                            <p className={"fw-light"}>Linking Requests</p>
                            <h3 className={"fw-bold display-6"}>330</h3>
                            <span className={"small fw-lighter float-end"}>
                                <a href="#" className={"text-decoration-none fw-normal"}>View List</a>
                            </span>
                        </div>
                    </div>
                </div>

                <div className={"mt-4"}>
                    <p className={"h5 fw-light"}>Items Linked</p>
                </div>
                <div className="container-fluid mt-3">
                    {allTransactions && allTransactions.map((t, key) => {
                        return <Item key={key} data={t}/>
                    })}
                </div>

                <br/>
                <br/>
                <br/>

                <div className={"mt-4"}>
                    <p className={"h5 fw-light"}>All Items</p>
                </div>
                <div className="container-fluid mt-3">

                    {allWatches && allWatches.map((watch, key) => {
                        return <Item_verified key={key} watch={watch}/>
                    })}
                </div>

            </div>
        </>
    )
}
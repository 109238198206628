import {useContext, useState} from "react";
import LoginContext from "../Utils/LoginContext";
import {Link, NavLink, useNavigate} from "react-router-dom";

export default function NavBar() {
    const {isLoggedIN, setLoggedIN} = useContext(LoginContext)
    const [isOpen, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleLogout = ()=>{
        localStorage.removeItem("setAdminLogin")
        setLoggedIN(false)
    }

    if (isLoggedIN){
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <div className="container py-3">
                        <Link className="navbar-brand fw-light" to={"/"}>Hello James 👋,</Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={()=>setOpen(current => !current)}
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className={`collapse navbar-collapse ${isOpen && "show"}`} id="navbarNavDropdown">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item px-3">
                                    <a className="nav-link" href={"#"} onClick={handleLogout}>Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    }

}
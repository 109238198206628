import "./Modal.css"
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {useNavigate} from "react-router-dom";
import watch from "../Assets/watch-front.png"
import man from "../Assets/avatar.png"
import link from "../Assets/icons8-link-50.png"
import {useEffect, useState} from "react";
import pullData from "../Utils/API";

export default function Modal({openState, setState, data}) {
    const [showDeliveryNumber, setDeliveryNumber] = useState(false)
    const [trackingNumber, setTrackingNumber] = useState("")

    const [is_dispatched, setDispatched] = useState(0)
    const [is_delivered, setDelivered] = useState(0)
    const trs = JSON.parse(data.blockchainData).transactionResponse

    useEffect(() => {
        console.log("data in modal")
        console.log(data)
        console.log(trs)

    }, [data])


    let handleChangeDelivery = (e) => {
        if (e.target.value === "dispatched") {
            setDeliveryNumber(true)
            setDispatched(1)
            setDelivered(0)
        } else {
            setDeliveryNumber(false)
            setTrackingNumber("0")
            setDispatched(1)
            setDelivered(1)
        }
    }
    let handleUpdate = () => {

        let fd = new FormData();
        fd.append("watch_serial", data.watch.serial_number)
        fd.append("is_dispatched", is_dispatched)
        fd.append("is_delivered", is_delivered)
        fd.append("tracking_number", trackingNumber)

        pullData.updateShipment(fd).then(r => {
            if (r.success) {
                window.location.reload()
            } else {
                alert("Error updating watch shipment data.")
            }
        })
    }

    return (<>
        <div className={"modal-wrapper"}>
            <div className="modal-body">
                <div className={"modal-content border-0 p-md-4"}>
                    <div className={"container"}>
                        <div className={"d-flex justify-content-between"}>
                            <div>
                                <h4 className={"fw-bold h5"}>{data?.user.firstname + " " + data?.user.lastname}</h4>
                                <p className={"fw-light m-0 small"}>{data?.user.email} | {data?.user.mobile}</p>
                                <p className={"fw-light m-0 small"}>{data?.user.full_address}</p>
                            </div>
                            <div>
                                <button className={"btn btn-link text-decoration-none text-dark"}
                                        onClick={() => setState(false)}
                                >Close
                                </button>
                            </div>
                        </div>
                        <div className={"mt-4 fw-bold"}>
                            <p>Item Linked</p>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-2 text-center">
                                    <img src={watch} alt="" className={"img-fluid"}/>
                                </div>

                                <div className="col-md-10 d-flex align-items-center">
                                    <div className="row w-100">
                                        <div className="col-md-5">
                                            <h5>Serial number {data?.watch.serial_number}</h5>
                                            <p className={"m-0"}>Production Date: {data?.watch.watch_production}</p>
                                            <p className={"m-0"}>NFT Linked: &nbsp;
                                                <a href={`https://apis.arab-watches.com/nft-images/${data?.watch.nftpath}`}
                                                   target={"_blank"}
                                                >{data?.watch.nftpath}</a></p>
                                        </div>
                                        <div className="col-md-7">
                                            <div className={"bg-light p-4 rounded w-100"}>

                                                <label className={"d-block mb-2"}>
                                                    <input type="radio" name={"delivery"}
                                                           className={"form-check-inline"}
                                                           value={"dispatched"}
                                                           onChange={handleChangeDelivery}/>
                                                    <span>Send Shipment</span>
                                                </label>


                                                {showDeliveryNumber &&
                                                    <label className={"d-block mb-2"}>
                                                        <input type="text" className={"form-control"}
                                                               placeholder={"Add shipment number"}
                                                               onChange={(e) => {
                                                                   setTrackingNumber(e.target.value)
                                                               }}
                                                        />
                                                    </label>
                                                }


                                                <label className={"d-block mb-2"}>
                                                    <input type="radio" name={"delivery"}
                                                           className={"form-check-inline"}
                                                           value={"delivered"}
                                                           onChange={handleChangeDelivery}/>
                                                    <span>Mark as Delivered</span>
                                                </label>
                                                <div className={"text-end"}>
                                                    <button className={"btn btn-sm btn-dark rounded-0"}
                                                            onClick={handleUpdate}
                                                    >Update
                                                        Status
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row my-4"}>
                                <div className="col-md-2 text-center">
                                    <img src={link} alt="" className={"img-fluid"} width={30}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 text-center">
                                    <img src={`https://apis.arab-watches.com/nft-images/${data?.watch.nftpath}`}
                                         alt="" className={"img-fluid rounded-circle"} width={100}/>
                                </div>
                                <div className="col-md-8 d-flex align-items-center">
                                    <div>
                                        <h5>{data?.user.firstname + " " + data?.user.lastname}</h5>
                                        <p className={"m-0"}>From Wallet: <br/> {trs.from}</p>
                                        <p className={"m-0"}>{data.user.created_time}</p>
                                    </div>

                                </div>
                            </div>
                            <hr/>
                            <div className={"mt-4 fw-bold"}>
                                <p className={"mb-1"}>Transaction Blockchain Info</p>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className={"m-0 small"}>Transaction Hash: {trs.transactionHash}</p>
                                    <p className={"m-0 small"}>Block Hash: {trs.blockHash}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="container mt-2">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-md-6 mb-3 d-flex align-items-center justify-content-end">
                                <button className={"custom-pill-button btn btn-dark rounded-pill px-5"}
                                        onClick={() => {
                                            setState(false)
                                        }}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>)
}
import {useEffect, useState} from "react";
import tc from "../Assets/tc-new.png";
import pullData from "../Utils/API";
import {useContext} from "react";
import ModalsContext from "../Utils/ModalsContext";

export default function Item_verified({watch}){
    const {setModalOpen, currentModalData, setCurrentModalData} = useContext(ModalsContext)


    return(
        <div className="row border-bottom border-dark">
            <div className="col-12 col-md-2 py-2 text-center">
                <img src={tc} alt="watch" className={"img-fluid"}/>
            </div>
            <div className="col-12 col-md-7 py-2 d-flex align-items-center">
                <div className={"ps-3"}>
                    <h4 className={"fw-bold h5"}>{watch.serial_number}</h4>
                    <div>
                        <p className={"fw-light m-0 small d-inline-block pe-5"}>{watch.is_verified === "1" ? "🟢 Verified" : "🔴 Unverified"}</p>
                        <p className={"fw-light m-0 small d-inline-block pe-2"}>{watch.is_minted === "1" ? "🟢 Minted": "🔴 Not Minted"}</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">

                <div>
                    {/*<button className={"btn btn-link text-decoration-none"}*/}
                    {/*        onClick={()=>{*/}
                    {/*            // setCurrentModalData({user: userData, watch: watchData})*/}
                    {/*            setModalOpen(true)*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*    View Details 👉*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    )
}
import logo from "../Assets/logo-sidenav.png"
import {useContext, useEffect, useState} from "react";
import ModalsContext from "../Utils/ModalsContext";
import "./LoginPage.css"
import LoginContext from "../Utils/LoginContext";
import pullData from "../Utils/API";


export default function LoginPage() {
    const {setLoggedIN} = useContext(LoginContext)

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [err, setErr] = useState("")

    useEffect(() => {
        const state = localStorage.getItem("setAdminLogin")
        if (state !== null) {
            if (state === "true") {
                setLoggedIN(true)
            } else {
                setLoggedIN(false)
            }

        }
    })

    function handleSubmitForm(event) {
        event.preventDefault()
        console.log("Submitting...")
        let fd = new FormData()
        fd.append("email", email)
        fd.append("password", pass)

        pullData.adminAuth(fd).then(r => {
            console.log(r)
            if (r.success === false){
                setErr(r.message)
                setLoggedIN(false)
                localStorage.setItem("setAdminLogin", "false")
            }else{
                console.log("login true")
                setLoggedIN(true)
                localStorage.setItem("setAdminLogin", "true")
            }
        })
    }

    return (
        <>
            <div className={"page-wrapper container"} id={"homepage"}>
                <div className="row align-items-center mt-5">
                    <form action="#" onSubmit={event => handleSubmitForm(event)}>
                        <div className="col-md-8 offset-md-2">
                            <div className="row text-center">
                                <div className="col-md-4 offset-md-4">
                                    <div className={"logo-wrapper"}>
                                        <img src={logo} alt="arab watches logo"/>
                                    </div>
                                </div>
                            </div>

                            <div className={"text-white mt-5 mb-3"}>
                                <h1 className={"fw-lighter display-6 text-center mb-0"}>Hello!</h1>
                            </div>

                            <div className={"text-white my-5 text-center px-md-5 login-form-wrapper"}>
                                <div>
                                    <input type="text" name={"Email"} placeholder={"Email"}
                                           onChange={(e) => {
                                               setEmail(e.target.value)
                                           }}
                                    />
                                </div>
                                <div>
                                    <input type="password" name={"Password"} placeholder={"Password"}
                                           onChange={(e) => {
                                               setPass(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>

                            <div className={"text-center"}>
                                {err !== "" && <span className={"small text-danger"}>{err}</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
                                <button className={"btn btn-outline-light rounded-pill text-decoration-none m-4 px-5"}
                                        type={"submit"}>
                                    Login
                                </button>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}